import React, { useEffect, useState } from "react";
import { Fade, Zoom, Bounce } from "react-reveal";
import { useTranslation } from 'react-i18next';
import ReactTypingEffect from 'react-typing-effect';
import './css/Top3.css'


export default function Top() {

    const { t } = useTranslation();

    const [userCountry, setUserCountry] = useState(null)



    useEffect(() => {
        //Seteo el país del usuario
        fetch("https://api.country.is")
            .then(res => res.json())
            .then(
                (result) => {
                    const targetCountry = countryCodes.find(country => country.code === result.country)
                    setUserCountry(targetCountry)
                    console.log("result", result, targetCountry)
                }
            )
        //----

    }, [])





    let basicFeatures =
        [{ icon: "fa fa-whatsapp ", title: t('top.socialMedia'), description: t('top.socialMediaDescription') },
        { icon: "fa fa-phone", title: t('top.callRecording'), description: t('top.callRecordingDescription') },
        { icon: "fa fa-map-marker", title: t('top.gpsLocation'), description: t('top.gpsLocationDescription') },
        { icon: "fa fa-desktop", title: t('top.screenRecording'), description: t('top.screenRecordingDescription') },
        { icon: "fa fa-microphone", title: t('top.environmentRecording'), description: t('top.environmentRecordingDescription') },
        { icon: "fa fa-eye-slash", title: t('top.hiddenMode'), description: t('top.hiddenModeDescription') }
        ]

    const gotoDemo = () => {
        window.open("https://cp.catwatchful.com?usr=ZGVtb0BkZW1vLmNvbXxkZW1vMTIz", "_blank")
    }
    const gotoDownload = () => {
        document.getElementById("download").scrollIntoView({ behavior: 'smooth' })
    }

    return (


        <div className="d-flex justify-content-center mt-4 pb-4 bg-animation"  >


            <div className="px-2" style={{ maxWidth: "900px" }}>


                <div className="h3">
                    <ReactTypingEffect
                        text={[t('top.parentalControl'), t('top.spy'), t('top.monitoring'), t('top.control'), t('top.security'), t('top.protection'), t('top.control'), t('top.security'), t('top.protection')]}
                        speed={50}
                        typingDelay={1000}
                        eraseDelay={2000}
                        eraseSpeed={50}
                        cursorRenderer={cursor => <h3 className="text-success">{'█'}</h3>}
                    />
                </div>

                <Fade duration={5000}>
                    <div className="badge badge-secondary rounded px-2 border border-secondary blueShadow" style={{ backgroundColor: "#0f171f", maxWidth: "300px" }}>
                        <span className="mr-1">{t('top.spyApp1', { country: userCountry?.countryName })} </span>
                        <img src={userCountry?.flagSrc} style={{ width: "30px", height: "25px", borderRadius: "4px" }} />
                    </div>
                </Fade>


                <div className="my-2 text-center" style={{ fontSize: "14px", maxWidth: "700px" }}>
                    <Bounce left> {t('top.stayInformed')}</Bounce>
                </div>

                {/* <div id='stars'></div>
                <div id='stars2'></div>



                <div className="wave"></div>
                <div className="wave"></div>
 */}


                <div class='light x1'></div>
                <div class='light x2'></div>

                <div class='light x5'></div>
                <div class='light x6'></div>
                <div class='light x7'></div>
                <div class='light x8'></div>
                <div class='light x9'></div>


                {/* <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul> */}




                <div className="  px-2 ">

                    <Fade >
                        <div className="d-flex justify-content-center ">
                            <img className="showImageIfResolutionIsMinor600 mt-4" src={process.env.PUBLIC_URL + "/images/home_imagetop.png"} style={{ maxWidth: "300px", maxHeight: "300px" }} />
                        </div>
                    </Fade>

                    <div className="d-flex">

                        <Zoom duration={1000} >
                            <div>
                                {basicFeatures.map((feature, index) => {
                                    return (

                                        <div className="" key={index}>
                                            <div className="mb-1">
                                                <i className={feature.icon + " faa-circle text-success blueShadow blackGradient"} style={{ width: "40px", color: "", fontSize: "23px", marginRight: '6px', textAlign: "center" }} />
                                                <span className="text-light font-weight-bolder " style={{ fontSize: "13px" }}>{feature.title}</span>
                                                <div style={{ paddingLeft: '50px', lineHeight: "13px", fontSize: "13px" }}>{feature.description}</div>
                                            </div>

                                        </div>
                                    )
                                }
                                )}
                            </div>
                        </Zoom>

                        <img className="hideImageIfResolutionIsMinor600" src={process.env.PUBLIC_URL + "/images/home_imagetop.png"} style={{ maxWidth: "400px", maxHeight: "300px", marginTop: "65px" }} />
                    </div>

                    <Fade >
                        <div className="d-flex  justify-content-center mt-4 ">
                            <button className="btn btn-outline-success mr-2" style={{ width: "170px" }} onClick={gotoDemo}><i className="fa fa-eye mr-1" /> {t('top.demo')}</button>
                            <div className="blob green"><button className="btn btn-success text-light" onClick={gotoDownload} style={{ width: "170px", height: "45px" }}><i className="fa fa-download mr-1" /> {t('top.download')}</button></div>
                        </div>
                    </Fade>

                </div>

                <div className="d-flex justify-content-center mt-3">
                    <img src={process.env.PUBLIC_URL + "/images/android.svg"} style={{ width: "27px" }} />
                    <span style={{ fontSize: "12px", color: "#8BC34A", paddingTop: "10px" }}> {t('top.availableAndroid')}</span>
                </div>




            </div>
        </div>

    );
}
const countryCodes = [{ countryName: "Uruguay", code: "UY", flagSrc: process.env.PUBLIC_URL + "/images/flags/uy.svg" },
{ countryName: "Argentina", code: "AR", flagSrc: process.env.PUBLIC_URL + "/images/flags/ar.svg" },
{ countryName: "Chile", code: "CL", flagSrc: process.env.PUBLIC_URL + "/images/flags/cl.svg" },
{ countryName: "Colombia", code: "CO", flagSrc: process.env.PUBLIC_URL + "/images/flags/co.svg" },
{ countryName: "Perú", code: "PE", flagSrc: process.env.PUBLIC_URL + "/images/flags/pe.svg" },
{ countryName: "México", code: "MX", flagSrc: process.env.PUBLIC_URL + "/images/flags/mx.svg" },
{ countryName: "España", code: "ES", flagSrc: process.env.PUBLIC_URL + "/images/flags/es.svg" },
{ countryName: "Estados Unidos", code: "US", flagSrc: process.env.PUBLIC_URL + "/images/flags/us.svg" },
{ countryName: "Brasil", code: "BR", flagSrc: process.env.PUBLIC_URL + "/images/flags/br.svg" },
{ countryName: "Paraguay", code: "PY", flagSrc: process.env.PUBLIC_URL + "/images/flags/py.svg" },
{ countryName: "Bolivia", code: "BO", flagSrc: process.env.PUBLIC_URL + "/images/flags/bo.svg" },
{ countryName: "Ecuador", code: "EC", flagSrc: process.env.PUBLIC_URL + "/images/flags/ec.svg" },
{ countryName: "Venezuela", code: "VE", flagSrc: process.env.PUBLIC_URL + "/images/flags/ve.svg" },
{ countryName: "Panamá", code: "PA", flagSrc: process.env.PUBLIC_URL + "/images/flags/pa.svg" },
{ countryName: "Costa Rica", code: "CR", flagSrc: process.env.PUBLIC_URL + "/images/flags/cr.svg" },
{ countryName: "Honduras", code: "HN", flagSrc: process.env.PUBLIC_URL + "/images/flags/hn.svg" },
{ countryName: "Guatemala", code: "GT", flagSrc: process.env.PUBLIC_URL + "/images/flags/gt.svg" },
{ countryName: "El Salvador", code: "SV", flagSrc: process.env.PUBLIC_URL + "/images/flags/sv.svg" },
{ countryName: "Nicaragua", code: "NI", flagSrc: process.env.PUBLIC_URL + "/images/flags/ni.svg" },
{ countryName: "Cuba", code: "CU", flagSrc: process.env.PUBLIC_URL + "/images/flags/cu.svg" },
{ countryName: "República Dominicana", code: "DO", flagSrc: process.env.PUBLIC_URL + "/images/flags/do.svg" },
{ countryName: "Puerto Rico", code: "PR", flagSrc: process.env.PUBLIC_URL + "/images/flags/pr.svg" },
{ countryName: "Haití", code: "HT", flagSrc: process.env.PUBLIC_URL + "/images/flags/ht.svg" },
{ countryName: "Jamaica", code: "JM", flagSrc: process.env.PUBLIC_URL + "/images/flags/jm.svg" },
{ countryName: "Trinidad y Tobago", code: "TT", flagSrc: process.env.PUBLIC_URL + "/images/flags/tt.svg" },
{ countryName: "Barbados", code: "BB", flagSrc: process.env.PUBLIC_URL + "/images/flags/bb.svg" },
{ countryName: "Guyana", code: "GY", flagSrc: process.env.PUBLIC_URL + "/images/flags/gy.svg" },
{ countryName: "Surinam", code: "SR", flagSrc: process.env.PUBLIC_URL + "/images/flags/sr.svg" },
{ countryName: "Belice", code: "BZ", flagSrc: process.env.PUBLIC_URL + "/images/flags/bz.svg" },
{ countryName: "Bahamas", code: "BS", flagSrc: process.env.PUBLIC_URL + "/images/flags/bs.svg" },
{ countryName: "Antigua y Barbuda", code: "AG", flagSrc: process.env.PUBLIC_URL + "/images/flags/ag.svg" },
{ countryName: "Santa Lucía", code: "LC", flagSrc: process.env.PUBLIC_URL + "/images/flags/lc.svg" },
{ countryName: "San Cristóbal y Nieves", code: "KN", flagSrc: process.env.PUBLIC_URL + "/images/flags/kn.svg" },
{ countryName: "San Vicente y las Granadinas", code: "VC", flagSrc: process.env.PUBLIC_URL + "/images/flags/vc.svg" },
{ countryName: "Granada", code: "GD", flagSrc: process.env.PUBLIC_URL + "/images/flags/gd.svg" },
{ countryName: "Dominica", code: "DM", flagSrc: process.env.PUBLIC_URL + "/images/flags/dm.svg" },
{ countryName: "Trinidad y Tobago", code: "TT", flagSrc: process.env.PUBLIC_URL + "/images/flags/tt.svg" },
{ countryName: "Barbados", code: "BB", flagSrc: process.env.PUBLIC_URL + "/images/flags/bb.svg" },
{ countryName: "Guyana", code: "GY", flagSrc: process.env.PUBLIC_URL + "/images/flags/gy.svg" },
{ countryName: "Surinam", code: "SR", flagSrc: process.env.PUBLIC_URL + "/images/flags/sr.svg" },
{ countryName: "India", code: "IN", flagSrc: process.env.PUBLIC_URL + "/images/flags/in.svg" },
{ countryName: "China", code: "CN", flagSrc: process.env.PUBLIC_URL + "/images/flags/cn.svg" },
{ countryName: "Japón", code: "JP", flagSrc: process.env.PUBLIC_URL + "/images/flags/jp.svg" },
{ countryName: "Corea del Sur", code: "KR", flagSrc: process.env.PUBLIC_URL + "/images/flags/kr.svg" },
{ countryName: "Indonesia", code: "ID", flagSrc: process.env.PUBLIC_URL + "/images/flags/id.svg" },
{ countryName: "Filipinas", code: "PH", flagSrc: process.env.PUBLIC_URL + "/images/flags/ph.svg" },
{ countryName: "Vietnam", code: "VN", flagSrc: process.env.PUBLIC_URL + "/images/flags/vn.svg" },
{ countryName: "Tailandia", code: "TH", flagSrc: process.env.PUBLIC_URL + "/images/flags/th.svg" },
{ countryName: "Malasia", code: "MY", flagSrc: process.env.PUBLIC_URL + "/images/flags/my.svg" },
{ countryName: "Singapur", code: "SG", flagSrc: process.env.PUBLIC_URL + "/images/flags/sg.svg" },
{ countryName: "Australia", code: "AU", flagSrc: process.env.PUBLIC_URL + "/images/flags/au.svg" },
{ countryName: "Nueva Zelanda", code: "NZ", flagSrc: process.env.PUBLIC_URL + "/images/flags/nz.svg" },
{ countryName: "Canadá", code: "CA", flagSrc: process.env.PUBLIC_URL + "/images/flags/ca.svg" },
{ countryName: "Reino Unido", code: "GB", flagSrc: process.env.PUBLIC_URL + "/images/flags/gb.svg" },
{ countryName: "Alemania", code: "DE", flagSrc: process.env.PUBLIC_URL + "/images/flags/de.svg" },
{ countryName: "Francia", code: "FR", flagSrc: process.env.PUBLIC_URL + "/images/flags/fr.svg" },
{ countryName: "Italia", code: "IT", flagSrc: process.env.PUBLIC_URL + "/images/flags/it.svg" },
{ countryName: "España", code: "ES", flagSrc: process.env.PUBLIC_URL + "/images/flags/es.svg" },
{ countryName: "Portugal", code: "PT", flagSrc: process.env.PUBLIC_URL + "/images/flags/pt.svg" },
{ countryName: "Suecia", code: "SE", flagSrc: process.env.PUBLIC_URL + "/images/flags/se.svg" },
{ countryName: "Noruega", code: "NO", flagSrc: process.env.PUBLIC_URL + "/images/flags/no.svg" },
{ countryName: "Dinamarca", code: "DK", flagSrc: process.env.PUBLIC_URL + "/images/flags/dk.svg" },
]