import React from "react";
import { Bounce, Fade, Zoom } from "react-reveal";
import { useTranslation } from 'react-i18next';

import { SectionTitle } from './CommonsComponents'

export default function Download(props) {
  const { ReactGA } = props;
  const { t } = useTranslation();

  const getCurrentAppVersion = () => {
    return "18.0"; // Supongo que esto es dinámico, puedes cambiarlo por tu lógica.
  };

  const handleDownloadClick = () => {
    return () => {
      window.open("https://catwatchful.pink/apk/getInstaller.php");
      ReactGA.event({ action: "nuevaDescarga", category: "eventoDescarga", value: 1 });
    };
  };

  return (
    <div className="blackGradient">
      <div id="download" className="container scrollMargin col-md-6 mt-4 pb-4">
        <SectionTitle name={t("download.title")} />


          <div className="" style={{ fontSize: "13px" }}>
            {t("download.description")}
          </div>
 

        <div className="py-1 mt-3 text-center">
          {/* <img style={{marginLeft:"5px", marginTop:"5px"}} src={process.env.PUBLIC_URL + "/images/logotext.png" className="img-responsive" width="60px" /> */}

          <span className="px-2 pt-1 text-light">
            <img src={process.env.PUBLIC_URL + "/images/logo.svg"} className="img-responsive mr-2" width="35px" />
            {t("download.version")}
            <span className="font-weight-bold">{getCurrentAppVersion()}</span>
          </span>
        </div>

        <div className="text-center mt-2">
          <div className="small">{t("download.qr")}</div>
          <img src={process.env.PUBLIC_URL + "/images/qr-code.png"} width={"150px"} alt="" />
        </div>

        <div className="text-center mt-4">
          <div className="small">{t("download.direct")}</div>

            <div className="d-flex justify-content-center mt-0">
              <div className="blob red">
                <button onClick={handleDownloadClick()} className="btn btn-danger text-light" style={{ width: "240px", height: "45px" }}>
                  <i style={{ fontSize: "28px" }} className="fa fa-cloud-download mr-1" />
                  {t("download.downloadButton")}
                </button>
              </div>
            </div>

        </div>

        <div className="mt-3 text-center" style={{ fontSize: "13px" }}>
          {t("download.readInstructions")}
        </div>
      </div>
    </div>
  );
}