import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import SocialMedia from "./SocialMedia";
import './css/Footer.css'
import ReactTypingEffect from 'react-typing-effect';
import { Link } from 'react-router-dom';

export default function Footer(props) {

    const { t } = useTranslation();
    const getCurrentYear = new Date().getFullYear();


    return (


        <footer id="" className="footer blackGradient" >

            <div className="container col-md-8 mt-4 pb-4" >
                {/* <hr className="bg-secondary" /> */}
                <div className="row pt-3">

                    <div className="col-12 col-md-4">
                        <div className="d-flex justify-content-center">
                            <div >
                                <img src={process.env.PUBLIC_URL + "/images/logo.svg"} className="img-responsive" width="50px" />
                                <img style={{ marginLeft: "5px", marginTop: "5px" }} src={process.env.PUBLIC_URL + "/images/logotext.png"} className="img-responsive" width="140px" />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 text-center pt-1 h5">
                        <ReactTypingEffect
                            text={[t('footer.typing1'), t('footer.typing2'), t('footer.typing3')]}
                            speed={50}
                            typingDelay={1000}
                            eraseDelay={2000}
                            eraseSpeed={50}
                            cursorRenderer={cursor => <h3 className="text-success">{'█'}</h3>}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <SocialMedia />
                    </div>

                </div>

                <div className="mt-2 mb-4">
                        <div className="text-light font-weight-bold">{t('footer.news')}</div>
                        <div className="" style={{ fontSize: "13px" }}>
                            <div><Link to="/reseller" >{t('footer.affiliate_program')}</Link></div>
                            <div><Link to="/android-client" >{t('footer.androidClient')}</Link></div>
                        </div>
                    </div>

                <div className="row  ">

                    <div className="col-6 col-md-4 ">
                        <div className="text-light font-weight-bold">{t('footer.legal')}</div>
                        <div style={{ fontSize: "13px" }}>
                            <div><Link to="/legal/#empresa" >{t('footer.company')}</Link></div>
                            <div><Link to="/legal/#privacidad" >{t('footer.privacy_policy')}</Link></div>
                            <div><Link to="/legal/#reembolso" >{t('footer.refund_policy')}</Link></div>
                            <div><Link to="/legal/#terminos" >{t('footer.terms_of_use')}</Link></div>
                            <div><Link to="/legal/#cluf" >{t('footer.cluf')}</Link></div>
                        </div>
                    </div>

                    <div className="col-6 col-md-4">
                        <div className="text-light font-weight-bold">{t('footer.support')}</div>
                        <div style={{ fontSize: "13px" }}>
                            <div><Link to="/#faq" >{t('footer.faq')}</Link></div>
                            <div><Link to="/suport" >{t('footer.contact')}</Link></div>
                            <div><Link to="/service-status" >{t('footer.service_status')}</Link></div>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 mt-4 mt-md-0">
                        <div className="text-light font-weight-bold">{t('footer.respalda')}</div>

                        <div className="row d-flex justify-content-between px-3 px-md-0 pt-2 " style={{ fontSize: "13px" }}>
                            <div><a href="https://www.pcisecuritystandards.org/" target="_blank"><img src={process.env.PUBLIC_URL + "/images/pci.svg"} style={{ width: "100px" }} /></a></div>
                            <div><a href="https://www.stopbullying.gov/" target="_blank"><img src={process.env.PUBLIC_URL + "/images/stopbullying.svg"} style={{ width: "100px" }} /></a></div>
                            <div><a href="https://www.securitymetrics.com/" target="_blank"><img src={process.env.PUBLIC_URL + "/images/creditsafe.svg"} style={{ width: "100px" }} /></a></div>
                        </div>
                    </div>




                </div>



                <hr className="bg-secondary" />

                <div className="text-center" style={{ fontSize: "10px" }}>
                    <div>{t('footer.copyright')} {getCurrentYear} Catwatchful </div>
                    <div>{t('footer.trademarks')}</div> 
                </div>

                <hr className="bg-secondary" />

                <div style={{ fontSize: "10px" }} >
                    <LegalNotice />
                </div>

            </div>

        </footer>

    )
}


const LegalNotice = () => {
    const { t } = useTranslation();
    const [showFullText, setShowFullText] = useState(false);

    const handleToggleText = () => {
        setShowFullText(!showFullText);
    };

    const fullText = t('footer.legal_notice_full');
    const halfText = fullText.substring(0, fullText.length / 4);

    return (

        <div onClick={() => { handleToggleText() }}>
            {showFullText ? fullText : `${halfText}...`}
            <span className="mx-2"   >
                {showFullText ? <u>{t('footer.show_less')}</u> : <u>{t('footer.show_more')}</u>}
            </span>
        </div>



    );
};
